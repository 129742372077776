;(function($, window, document, undefined) {
	var $win = $(window);
	var $doc = $(document);
	map_rendered = false;

	var crbRenderCaptcha = function() {
		var recaptchaInterval = window.setInterval( function() {
			if ( typeof ( grecaptcha) !== 'undefined' ) {
				$('.ginput_recaptcha').each( function() {
					if ( $(this).find('*').length > 0 ) {
						return;
					}

					var sitekey = $(this).data('sitekey');

					if ( sitekey ) {
						grecaptcha.render( $(this).attr('id'), {
							sitekey: sitekey
						} );
					}
				});
				clearInterval( recaptchaInterval );
			}
		}, 100 );
	}

	function googleTranslateElementInit() {
		new google.translate.TranslateElement( {
			pageLanguage: 'en',
			includedLanguages: 'en,es,ar,fa,tl,vi,zh-CN,zh-TW'
		}, 'google_translate_element');

		var englishInterval = window.setInterval( function() {
			if (  $doc.find('.goog-te-combo option').length > 0 ) {
				$doc.find('.goog-te-combo option').eq(0).text('ENGLISH');
				$doc.find('#google_translate_element').removeClass('hide');
				clearInterval( englishInterval );
			}
		}, 100 );

	}

	$('document').on( 'click', '.goog-close-link img', function() {
		$doc.find('.goog-te-combo option').eq(0).text('ENGLISH');
	} );

	$doc.ready(function() {
		crbRenderCaptcha();

		$(document).bind('gform_post_render', function(){
			crbRenderCaptcha();
		});



		$( '.crb_custom_btn' ).each( function() {
			var text = $(this).css( 'color' );
			var bg   = $(this).css( 'background-color' );

			$(this).on( 'mouseenter', function() {
				$( this ).css( {
					'color' : bg,
					'background-color' : text,
				});
			} );

			$( this ).on( 'mouseleave', function() {
				$( this ).css( {
					'color' : text,
					'background-color' : bg,
				});
			} );
		});

		// Add class if has dropdown
		$('nav[class^="nav-"] li').each(function() {
			var $this = $(this);
			if ( $this.find('ul').length ) {
				$this.addClass('has-dropdown');
			};
		});

		// Nav utilities dropdown
		$('.nav-utilities .has-dropdown > a').on('click', function(event) {
			event.preventDefault();

			$(this).parent('li').toggleClass('active');
		});

		// Get image src and puts in to background
		$('.fullsize-bg').each(function() {
			var $img = $(this);

			$img.addClass('hidden');
			$(this).parent().addClass('container-fullsize').css('background-image', 'url('+ $img.attr('src') +')');
		});

		// Sliders
		$('.slider-primary .owl-carousel').owlCarousel({
			loop:true,
			margin:0,
			nav:true,
			items:1,
			smartSpeed:700,
			autoplay:true,
			autoplayTimeout:5000,
		});

		// Popup
		$('.link-popup').on( 'click', function(event) {
			event.preventDefault()
			$.post(
			ajaxurl,
			{
			  'action' : "crb_add_popup",
			  'memberID' : $(this).data( 'member-id' )
			})
			.success( function( response ) {
				$.magnificPopup.open({
					items : {
						src: response,
						type: 'inline',
						midClick: true,
						removalDelay: 300,
						// mainClass: 'mfp-fade',
					}
				});
			});
		});

		// Guided search accordion
		$('.heading_list').click(function(){
			$(this).next('.content_list').slideToggle();
		});

		// Parallax Effect
		function introScroll() {
			var scrolled = $win.scrollTop();

			if ( $win.width() > 1023 ) {
				$('.intro-primary .intro-image').css('background-position', 'center ' + (scrolled * 0.5) + 'px');
			} else {
				$('.intro-primary .intro-image').css('background-position', 'center 0');
			}
		};

		// Google Maps
		window.googleMaps = function() {
			if ($('.gmap').length) {
				function initialize() {
					var mapCanvas = document.getElementById('map');

					// console.log( JSON.parse( mapCanvas.dataset.outline ));
					// return;
					var lat = mapCanvas.dataset.lat;
					var lng = mapCanvas.dataset.lng;

					var mapOptions = {
						center: new google.maps.LatLng(lat, lng),
						zoom: 14,
						scrollwheel: false,
						disableDefaultUI: false,
						mapTypeId: google.maps.MapTypeId.ROADMAP,
					};

					var map = new google.maps.Map(mapCanvas, mapOptions);
					var outline = JSON.parse( mapCanvas.dataset.outline );
					var bounds = new google.maps.LatLngBounds();

					if(outline.multi){
						var shapeOverlay = [];
						for(i = 0; i < outline.outlines.length; i++){
							if ( typeof outline.outlines[i][0].lat === 'object' ) {
								continue;
							}

							var tempOverlay = new google.maps.Polygon({
								paths: outline.outlines[i],
								strokeColor: '#ff0000',
								strokeOpacity: 0.5,
								strokeWeight: 1,
								fillColor: '#ff0000',
								fillOpacity: 0.1
							});

							tempOverlay.setMap(map);

							shapeOverlay.push( tempOverlay );
						}

						for ( i = 0; i < shapeOverlay.length; i++ ) {
							shapeOverlay[i].getPath().forEach(function (element, index) { bounds.extend(element);
							} );
						}
					} else {
						var shapeOverlay = new google.maps.Polygon({
							paths: outline,
							strokeColor: '#ff0000',
							strokeOpacity: 0.5,
							strokeWeight: 1,
							fillColor: '#ff0000',
							fillOpacity: 0.1
						});

						shapeOverlay.setMap(map);
						shapeOverlay.getPath().forEach(function (element, index) { bounds.extend(element);
							} );
					}

					map.fitBounds( bounds );
				}
				google.maps.event.addDomListener(window, 'load', initialize);
			};
		};

		window.googleMaps();

		// Autocomplete
		$('.search-primary .search-field, #field-search').autocomplete({
			source: '/term-autocomplete.php',
			delay: 150,
			minLength: 3,
			select: function(event, ui){
				$('.search-primary .search-field, #field-search').val(ui.item.value);
			}
		});

		// Remove filter element
		$('.list-filter a').on('click', function(event) {
			event.preventDefault();

			$(this).parent().remove();
		});

		// Toggle responsive nav
		$('.nav-trigger').on('click', function(event) {
			event.preventDefault();

			$(this).toggleClass('active');
			$('.header-inner').toggleClass('active');
		});

		// Toggle filter on mobile
		$('.list-nav a').on('click', function(event) {
			event.preventDefault();

			if( $(this).hasClass('current') ) {
				$('.list-nav .dropdown').removeClass('active');
				$('.list-nav .current').removeClass('current');
			} else {
				$('.list-nav .dropdown').removeClass('active');
				$('.list-nav .current').removeClass('current');
				$(this).toggleClass('current');
				$(this).next('.dropdown').toggleClass('active');
			};
		});

		// Turn list to <select>
		var $list   = $('.nav-secondary ul'),
			$selectHolder = $('<div class="select-holder" />');
			$select = $('<select />');

		$list.children('li').each(function(index) {
			$select.append($('<option />').attr('value', index).html($(this).html()));
		});

		$doc.on( 'change', '.nav-secondary select', function() {
			var $selectedOption = $( this ).find( 'option:selected' );

			var href = $selectedOption.find( 'a' ).attr( 'href' );
			window.location.href = href;
		} )

		$selectHolder.insertAfter($list);
		$select.appendTo($selectHolder);

		function barHeight() {
			if( $('.bar').length ) {
				$('.container-outer').css('padding-top', $('.bar').outerHeight() + $('.header').outerHeight() );
				$('.header').css('top', $('.bar').outerHeight());
			};
		};

		$('.bar .link-close').on('click', function(event) {
			event.preventDefault();

			$('.bar').remove();
			$('.container-outer').css('padding-top', $('.header').outerHeight() );
			$('.header').css('top', '0');
		});

		// Google translate default text
		$doc.on( 'change', '.goog-te-combo', function() {
			$(this).find( 'option' ).eq(0).text('ENGLISH');
		});



		// setTimeout(function() {
		// 	$doc.find('.goog-te-combo option').eq(0).text('ENGLISH');
		// 	$doc.find('#google_translate_element').removeClass('hide');
		// }, 500);

		updateSearchFilters();

		$('body').on('change', '#search-page-form select, #search-page-form input[type="radio"], .search-primary #s, #zip, #field-pages', function(){
			updateSearchFilters();
			updateSearchResults();
		});

		// $('#search-reset').click(function(){
		// 	$('input[name="gender"]').prop('checked', false);
		// 	$('input[name="age"]').prop('checked', false);
		// 	$('input[name="population"]').prop('checked', false);
		// 	$('#field-type').val('');
		// 	$('#zip').val('');
		// 	$('#s').val('');
		// 	updateSearchFilters();
		// 	updateSearchResults();
		// });

		$win
			.on('load', function() {
				googleTranslateElementInit();

				// Popup on load
				if( $('#popup-load').length ) {
					setTimeout(function() {
						$.magnificPopup.open({
							items: {
								src: '#popup-load',
								type: 'inline'
							},
							type: 'inline',
							removalDelay: 300,
							mainClass: 'mfp-fade'
						}, 0);
					}, 2000);
				};
			})
			.on('scroll', function() {
				introScroll();
			})
			.on('load resize', function() {
				// Move elements on resize
				if( $win.width() < 768 ) {
					$('.section-search-primary .section-aside').appendTo('.list-nav .dropdown-primary');

					$('.panel-group .panel-title a').removeClass('collapsed');
					$('.panel-group .panel-collapse').addClass('in');
				} else {
					$('.section-search-primary .section-aside').prependTo('.section-search-primary .section-body');

					$('.panel-group .panel-title a').addClass('collapsed');
					$('.panel-group .panel-collapse').removeClass('in');
				};

				// Homepage slider height
				$('.slider-primary .slide').height( $('.intro-inner').outerHeight() );
				$('.list-boxes').height( $('.intro-inner').outerHeight() );

				// Check if bar is visible
				barHeight();
			});

		$doc.on('click', function(event) {
			var $target = $(event.target);

			if (!$target.is('.has-dropdown, .has-dropdown *')) {
				$('.nav-language .dropdown, .has-dropdown').removeClass('active');
			}
		});

		$('#video-form').submit(function(e){
			e.preventDefault();

			var destination = $(this).find('select').val();
			if(destination && destination != ''){
				// window.location.href = destination;
				window.open(destination, '_blank');
			}
		});

        // Popup on load
        if( $('.call-me-button').length ) {
            $('.call-me-button').magnificPopup({
                items: {
                    src: '#call-me',
                    type: 'inline'
                },
                type: 'inline',
                removalDelay: 300,
                mainClass: 'mfp-fade'
            }, 0);

            $('#clickForm').submit(function(e){
            	e.preventDefault();

            	$('.loading').show();

                $.ajax({
					type: "POST",
					url: $(this).attr('action'),
					data: $(this).serialize(),
					error: function() {
                        $('.loading').hide();
                        $('.hide-after').hide();
                        $('.show-after').show();
					},
					success: function(data) {
                        $('.loading').hide();
                        $('.hide-after').hide();
                        $('.show-after').show();
                    }
				});

				// $.post($(this).attr('action'), $(this).serialize(), function(data, status){
				//
                //
				// 	console.log('post');
				// 	console.log(status);
                //
				// 	if(status == 'success'){
                 //        $('.hide-after').hide();
                 //        $('.show-after').show();
				// 	} else {
				//
				// 	}
                //
                 //    //$.magnificPopup.close();
				// });

            	return false;
			});
        };

        // Popup on load
        if( $('.click-to-chat-button').length ) {
            $('.click-to-chat-button').magnificPopup({
                items: {
                    src: '#click-to-chat',
                    type: 'inline'
                },
                type: 'inline',
                removalDelay: 300,
                mainClass: 'mfp-fade'
            }, 0);

            $('#chatSelect').change(function(){
            	if($(this).val() != '0'){
            		$('#chatSubmit').prop('disabled', false);
				} else {
                    $('#chatSubmit').prop('disabled', true);
				}
			});

            $('#chatSubmit').click(function() {
                var url = document.getElementById('chatSelect');
                if (url) {
                    var selectedUrl = url.options[url.selectedIndex].value;
                    var windowName = "chat211";
                    var windowSize = "width=500,height=600,left=300,top=150,location=no,menubar=no,resizable=no,scrollbars=no,toolbar=no"
                    window.open(selectedUrl, windowName, windowSize );
                    $.magnificPopup.close();
                } else {
                    alert("Please select a chat category");
                }

            });
        };
	});

	function updateSearchResults(){
		$('.results').html('<li>Loading...</li>');

		var gender_val = $('input[name="gender"]:checked').val();
		var age_val = $('input[name="age"]:checked').val();
		var population_val = $('input[name="population"]:checked').val();
		var type = $('#field-type').val();
		var zip = $('#zip').val();
		var keyword = $('#s').val();
		var count = $('#field-pages').val();

		var query_url = '/search-resources/?search=true';

		if(keyword){
			query_url += '&keyword=' + keyword;
		}

		if(type){
			query_url += '&type=' + type;
		}

		if(gender_val){
			query_url +=  '&gender=' + gender_val;
		}

		if(age_val){
			query_url += '&age=' + age_val;
		}

		if(population_val){
			query_url += '&population=' + population_val;
		}

		if(zip){
			query_url += '&zip=' + zip;
		}

		if(count){
			query_url += '&count=' + count;
		}

		history.pushState({}, window.document.title, query_url);

		$.get(query_url, function(data){
			var $data = $(data);
			var content = $data.find('.results');
			var service_types = $data.find('#field-type');
			var pagination = $data.find('.paging');
			$('.results').replaceWith(content);
			$('#field-type').replaceWith(service_types);
			$('.paging').replaceWith(pagination);
			initMap();
		});
	}

	function updateSearchFilters(){
		var filters = false;
		var target_wrap = $('#applied-filters');
		var target = target_wrap.find('.list-filter');
		var gender = $('input[name="gender"]');
		var gender_val = $('input[name="gender"]:checked').val();
		var age = $('input[name="age"]');
		var age_val = $('input[name="age"]:checked').val();
		var population = $('input[name="population"]');
		var population_val = $('input[name="population"]:checked').val();
		var type = $('#field-type');
		var zip = $('#zip');
		var keyword = $('#s');

		target.html('');

		if(keyword.val()){
			filters = true;
			var link = $('<li><a href="#"><strong>Keyword:</strong> ' + keyword.val() + '</a></li>');
			link.click(function(e){
				e.preventDefault();
				$(this).remove();
				keyword.val('');
				updateSearchResults();
			});
			link.appendTo(target);
		}

		if(zip.val()){
			filters = true;
			var link = $('<li><a href="#"><strong>ZIP Code:</strong> ' + zip.val() + '</a></li>');
			link.click(function(e){
				e.preventDefault();
				$(this).remove();
				zip.val('');
				updateSearchResults();
			});
			link.appendTo(target);
		}

		if(type.val()){
			filters = true;
			var link = $('<li><a href="#"><strong>Service Type:</strong> ' + type.find('option:selected').text() + '</a></li>');
			link.click(function(e){
				e.preventDefault();
				$(this).remove();
				type.find('option:selected').prop('selected', false);
				updateSearchResults();
			});
			link.appendTo(target);
		}

		if(gender_val){
			filters = true;
			var link = $('<li><a href="#"><strong>Sex:</strong> ' + gender_val + '</a></li>');
			link.click(function(e){
				e.preventDefault();
				$(this).remove();
				gender.prop('checked', false);
				updateSearchResults();
			});
			link.appendTo(target);
		}

		if(age_val){
			filters = true;
			var link = $('<li><a href="#"><strong>Age:</strong> ' + age_val + '</a></li>');
			link.click(function(e){
				e.preventDefault();
				$(this).remove();
				age.prop('checked', false);
				updateSearchResults();
			});
			link.appendTo(target);
		}

		if(population_val){
			filters = true;
			var link = $('<li><a href="#"><strong>Population:</strong> ' + population_val + '</a></li>');
			link.click(function(e){
				e.preventDefault();
				$(this).remove();
				population.prop('checked', false);
				updateSearchResults();
			});
			link.appendTo(target);
		}

		if(filters){
			target_wrap.removeClass('hidden');
		} else {
			target_wrap.addClass('hidden');
		}
	}
})(jQuery, window, document);
